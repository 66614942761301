import React from "react"
import PropTypes from "prop-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

export default function Cta({ content, richContent, ctaText, ctaButtonText }) {
  return (
    <div className="container mt24 pad-30">
      {(content || richContent) && (
        <div className="row mb16">
          <div className="col-sm-12 text-center">
            {richContent ? (
              documentToReactComponents(richContent)
            ) : (
              <p className="lead dark-text  mb8">{content}</p>
            )}
          </div>
        </div>
      )}
      <div className="row text-center mb16">
        {ctaText && <h4 className="uppercase mb8 text-center">{ctaText}</h4>}
        {ctaButtonText && (
          <a className="btn btn-sm btn-filled mb0 inner-link" href="#form">
            ctaButtonText
          </a>
        )}
      </div>
    </div>
  )
}

Cta.propTypes = {
  content: PropTypes.string,
  richContent: PropTypes.object,
}
